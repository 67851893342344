<template>
    <div class="flex justify-center items-center pt-20 pb-10">
        <div class="flex w-[full] md:max-w-[50%] flex-col justify-center items-center">
            <p class="text-white text-6xl text-center font-[Oswald] pb-10 w-[90%]">Os nossos serviços</p>
            <p class="text-white text-lg md:text-xl pb-10 w-[80%]">Descubra o leque de serviços excepcionais que temos à
                sua
                disposição. Na nossa barbearia, aperfeiçoamos a arte do grooming masculino. Cada corte é executado com
                atenção meticulosa aos detalhes, para garantir que cada cliente sai da nossa barbearia com o visual e
                sensação
                que deseja.</p>
            <div class="w-[90%] md:max-w-[500px] flex justify-center flex-col gap-4 items-center text-left pb-10">
                <div class="flex justify-center items-center border-2 rounded-md w-full p-5 text-white ">
                    <img src="@/assets/scissors2.svg" alt="" class="pr-5 w-20 md:w-32">
                    <div class="flex-1">
                        <h1 class="text-xl font-semibold">Combo</h1>
                        <p class="text-xs pr-4 text-left">Reserve o nosso pacote completo para um look coeso e
                            personalizado, enquanto a nossa equipa renova a sua aparência com o corte de barba e cabelo.
                        </p>
                    </div>
                    <div class="leading-tight">
                        <p class="text-xl font-semibold">DESDE</p>
                        <p class="text-[#EE4445] text-2xl font-bold">€18</p>
                    </div>
                </div>
                <div class="flex justify-center items-center border-2 rounded-lg w-full p-5 text-white ">
                    <img src="@/assets/electric_shaver.svg" alt="" class="pr-5 w-20 md:w-32">
                    <div class="flex-1">
                        <h1 class="text-xl font-semibold">Cabelo</h1>
                        <p class="text-xs pr-4 text-left">Usufrua do nosso corte de cabelo tradicional e moderno, criado
                            à sua medida pelos nossos talentosos cabeleireiros.</p>
                    </div>
                    <div class="leading-tight">
                        <p class="text-xl font-semibold">DESDE</p>
                        <p class="text-[#EE4445] text-2xl font-bold">€13</p>
                    </div>
                </div>
                <div class="flex justify-center items-center border-2 rounded-md w-full p-5 text-white ">
                    <img src="@/assets/razor.svg" alt="" class="pr-5 w-20 md:w-32">
                    <div class="flex-1">
                        <h1 class="text-xl font-semibold">Barba</h1>
                        <p class="text-xs pr-4 text-left">Com um corte de barba preciso, a nossa equipa realça as suas
                            melhores características, aperfeiçoando a sua forma e estilo.</p>
                    </div>
                    <div class="leading-tight">
                        <p class="text-xl font-semibold">DESDE</p>
                        <p class="text-[#EE4445] text-2xl font-bold">€7</p>
                    </div>
                </div>

            </div>
            <p class="text-white text-lg md:text-xl w-[80%]">Lembramos que todos os nossos serviços são projetados para
                proporcionar uma experiência relaxante e indulgente, permitindo que você escape da agitação do dia a
                dia,
                enquanto se entrega nas mãos dos nossos barbeiros experientes. Reserve o seu lugar hoje mesmo e
                deixe-nos encarregar da sua aparência com a maior preocupação pela precisão e detalhe.</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServicesComponent',
    }
</script>

<style scoped lang="postcss"></style>