<template>
    <canvas ref="snowCanvas" class="snow-canvas"></canvas>
</template>

<script>
    export default {
        mounted() {
            this.createSnowEffect();
        },
        methods: {
            createSnowEffect() {
                const canvas = this.$refs.snowCanvas;
                const ctx = canvas.getContext('2d');
                const snowflakes = [];
                const maxSnowflakes = 30;

                // Resize canvas
                const resizeCanvas = () => {
                    canvas.width = window.innerWidth;
                    canvas.height = window.innerHeight;
                };
                resizeCanvas();
                window.addEventListener('resize', resizeCanvas);

                // Create Snowflake class
                class Snowflake {
                    constructor() {
                        this.reset();
                    }
                    reset() {
                        this.x = Math.random() * canvas.width;
                        this.y = Math.random() * -canvas.height;
                        this.radius = Math.random() * 3 + 1;
                        this.speed = Math.random() * 2 + 1;
                        this.wind = Math.random() * 2 - 1;
                    }
                    update() {
                        this.y += this.speed;
                        this.x += this.wind;
                        if (this.y > canvas.height || this.x > canvas.width || this.x < 0) {
                            this.reset();
                        }
                    }
                    draw() {
                        ctx.beginPath();
                        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
                        ctx.fillStyle = 'white';
                        ctx.fill();
                    }
                }

                // Initialize snowflakes
                for (let i = 0; i < maxSnowflakes; i++) {
                    snowflakes.push(new Snowflake());
                }

                // Animate snowflakes
                const animate = () => {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    snowflakes.forEach((snowflake) => {
                        snowflake.update();
                        snowflake.draw();
                    });
                    requestAnimationFrame(animate);
                };
                animate();
            },
        },
    };
</script>

<style>
    .snow-canvas {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 1000;
    }
</style>