<template>
    <div class="flex w-full items-center flex-col">
        <div class="flex flex-1 md:w-full w-[80%] justify-center items-center pb-10 gap-5">
            <img src="@/assets/star.svg" alt="" class="h-[50px]">
            <p class="text-white text-6xl text-center font-[Oswald]">Wall of Fame</p>
            <img src="@/assets/star.svg" alt="" class="h-[50px]">
        </div>
        <div class="flex justify-center pb-10 h-auto">
            <div class="grid grid-cols-2 md:grid-cols-4 md:grid-rows-3 gap-2 md:gap-6 w-[90%] md:w-[70%]">
                <img src="@/assets/image_1.jpg" class="w-full object-cover object-center  rounded-md shadow-md">
                <img src="@/assets/image_3.jpg" class="w-full object-cover object-center  rounded-md shadow-md ">
                <img src="@/assets/image_2.jpg" class="w-full object-cover object-center  rounded-md shadow-md">
                <img src="@/assets/image_4.jpg" class="w-full object-cover object-center  rounded-md shadow-md">
                <img src="@/assets/image_5.jpg" class="w-full object-cover object-center  rounded-md shadow-md">
                <img src="@/assets/image_6.jpg" class="w-full object-cover object-center  rounded-md shadow-md">
                <img src="@/assets/image_7.jpg" class="w-full object-cover object-center  rounded-md shadow-md">
                <img src="@/assets/image_8.jpg" class="w-full object-cover object-center  rounded-md shadow-md">
                <img src="@/assets/image_9.jpg" class="w-full object-cover object-center  rounded-md shadow-md">
                <img src="@/assets/image_10.jpg" class="w-full object-cover object-center  rounded-md shadow-md">
                <img src="@/assets/image_11.jpg" class="w-full object-cover object-center  rounded-md shadow-md">
                <img src="@/assets/image_12.jpg" class="w-full object-cover object-center  rounded-md shadow-md">
                <!-- <img src="@/assets/image_13.jpg" class="w-full object-cover object-center h-full rounded-md shadow-md"> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="">

</style>