<template>
    <div class="flex flex-col w-[90%] md:w-[50%] items-center justify-center mx-auto -mb-10">
        <p class="text-white text-6xl text-center font-[Oswald]">A Arte do Corte</p>
        <p class="text-white text-3xl text-center font-light py-5">Descubra os Nossos Talentosos
            Barbeiros</p>
        <div class="w-full flex flex-col md:flex-row justify-center md:gap-x-20 gap-y-20">
            <div class="flex flex-col items-center h-[500px] lg:flex-1">
                <div
                    class="w-[100%] h-[100%] bg-[url('@/assets/beto.webp')] bg-white bg-top bg-cover bg-no-repeat border rounded-md">
                </div>
                <p class="text-[#EE4445] text-4xl font-[Oswald] font-bold drop-shadow-md pt-5">BETO ALMEIDA</p>
            </div>

            <div class="flex flex-col items-center h-[500px] lg:flex-1">
                <div
                    class="w-[100%] h-[100%] bg-[url('@/assets/bruno.webp')] bg-cover bg-top bg-no-repeat border rounded-md">
                </div>
                <p class="text-[#EE4445] text-4xl font-[Oswald] font-bold drop-shadow-md pt-5">BRUNO ROCHA</p>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'BarbersComponents',
        data() {
            return {

            }
        },
    }
</script>

<style lang="postcss">
    .reveal {
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }
</style>